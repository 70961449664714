import React from 'react'
import { FaArrowRight } from 'react-icons/fa'

import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Container } from '../components/Container'

const NotFoundPage = () => (
  <Layout spaceTop={true}>
    <SEO title="404: Sidan hittades inte" />

    <Container>
      <h1 className="text-2xl md:text-4xl mb-3 text-gray-700">404: Vi kunde tyvärr inte hitta sidan du sökte</h1>
      <a href="/" className="font-bold text-blue-600 underline inline-flex items-center">
        <span className="mr-2">Det bästa vi kan göra är att gå till startsidan</span>
        <FaArrowRight />
      </a>
    </Container>
  </Layout>
)

export default NotFoundPage
